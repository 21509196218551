const pdfmake = require('pdfmake');
global.pdfmake = pdfmake;

window.JSZip = require('jszip');

const dtButtons = require('datatables.net-buttons');
global.dtButtons = dtButtons;

require('datatables.net-buttons-bs5');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');

var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css'
import language from 'datatables.net-plugins/i18n/fr-FR.mjs';
language.buttons.copyTitle  = 'Ajouté au presse-papiers'
language.buttons.copySuccess= {
            _: '%d lignes copiées',
            0: 'Aucune ligne copiée',
            1: '1 ligne copiée'
        }
window.language = language
